import React, { useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { useShoppingCart } from "use-shopping-cart"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import classnames from "classnames"
import styled from "styled-components"

import { TabContent, TabPane, Button, Table, Label } from "reactstrap"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { FormGroup, InputGroup, Input, InputGroupAddon } from "reactstrap"
import { FormFeedback } from "reactstrap"

import Layout from "../layout/layout"
import SEO from "../layout/seo"
import SliderImages from "../../components/sliderImages"
import ProductSlider from "../sections/ProductSlider"

import { AiOutlineShoppingCart, AiOutlineCheck } from "react-icons/ai"

export default function ProductDetails(props) {
  const product = props.data.contentfulProduct
  const { name, category, subcategory, description, descriptionLong } = product
  const { id, slug, price, details, linkedProducts } = product

  const images = product.images
    ? [product.mainImage, ...product.images]
    : [product.mainImage]

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => text,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
    },
  }

  const moreProducts =
    linkedProducts ||
    subcategory
      .reduce((products, c) => products.concat(c.product), [])
      .filter((product) => product.id !== id)

  return (
    <Layout>
      <SEO
        title={name}
        product={{
          name:
            (category.name === "Poster"
              ? "Aquarell-Poster "
              : "Aquarell-Karte ") +
            name +
            subcategory.reduce((acc, c) => acc + " | " + c.name, "") +
            " | " +
            category.name +
            " | " +
            (category.name === "Poster" ? "Größe A4" : "Größe A6"),
          description: renderRichText(descriptionLong, options).reduce(
            (acc, text) => acc + text[0],
            ""
          ),
          sku: id,
          image: images.map((img) => "https:" + img.file.url),
          category:
            "Papierwaren & Partybedarf < Papier < Schreibwaren < Postkarten",
          offer: {
            price: (price / 100).toLocaleString("en", {
              // minimumFractionDigits: 2,
            }),
          },
          url: `https://www.artgreetings.de/product/${slug}/`,
        }}
      />
      <Container fluid className="my-5">
        <Row>
          <Col md="6" className="mb-3 mb-md-0">
            <SliderImages images={images} />
          </Col>
          <Col md="6" className="d-flex flex-column  pl-lg-5 pb-2 py-lg-3">
            <div className="mb-4 mb-xl-5">
              <CategoryLink category={category} subcategory={subcategory} />
              <h1 className="mb-3 text-primary">{name}</h1>
              <h5 className="text-secondary">
                ab{" "}
                {(price / 100).toLocaleString("de", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </h5>
            </div>

            {descriptionLong && description && (
              <div style={{ whiteSpace: "pre-line" }} className="mb-5">
                {description.description}
              </div>
            )}
            {/* <div className="mt-auto mb-xl-5"> */}
            {category.name === "Poster" ? (
              <AddToCard product={product} />
            ) : (
              <AddToCardFormik product={product} />
            )}
            {/* <EtsyLink product={product} /> */}
            {/* </div> */}
          </Col>
        </Row>
      </Container>
      {(description || descriptionLong || details) && (
        <ProductInfo product={product} />
      )}

      <Container fluid className="mt-4 mt-md-5">
        <hr />
      </Container>
      <ProductSlider
        title="Das könnte dir auch gefallen"
        className="mb-5 py-5"
        settings={{ dots: false }}
        products={moreProducts}
      />
    </Layout>
  )
}

const AddToCard = ({ product }) => {
  const [cartUpdated, setCartUpdated] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const { addItem } = useShoppingCart()

  return (
    <AddToCardWrapper className="mt-auto">
      <Label className="small mb-1 text-muted">Stückzahl</Label>
      <InputGroup className="mb-4">
        <Input
          style={{ borderColor: "#6c757d" }}
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
        <InputGroupAddon addonType="append">
          <Button
            className="px-4"
            outline
            disabled={quantity === 1}
            onClick={() => setQuantity(quantity - 1)}
          >
            -
          </Button>
        </InputGroupAddon>
        <InputGroupAddon addonType="append">
          <Button
            className="px-4"
            outline
            onClick={() => setQuantity(quantity + 1)}
          >
            +
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <Button
        className="mb-2"
        block
        size="lg"
        color={cartUpdated ? "primary" : "dark"}
        disabled={cartUpdated}
        onClick={() => {
          setCartUpdated(true)
          addItem({ ...product }, { count: quantity })
          setTimeout(() => setCartUpdated(false), 2000)

          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "add_to_cart",
            currency: "EUR",
            value: product.price / 100,
            items: [
              {
                item_id: product.id,
                item_name: product.name,
                price: product.price / 100,
                quantity: quantity,
              },
            ],
          })
        }}
      >
        {!cartUpdated ? (
          <div className="d-flex align-items-center justify-content-center">
            <AiOutlineShoppingCart size={18} className="mr-2" />
            <span>In den Warenkorb legen</span>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <AiOutlineCheck size={18} className="mr-2" />
            <span>Artikel hinzugefügt</span>
          </div>
        )}
      </Button>
      <div className="text-center small">
        Versand innerhalb von 1-3 Werktagen.
      </div>
    </AddToCardWrapper>
  )
}

const AddToCardFormik = ({ product }) => {
  const { id, price, personalization } = product
  const [cartUpdated, setCartUpdated] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const { addItem } = useShoppingCart()

  const validationSchema = Yup.object({
    variation: Yup.object().shape({
      name: Yup.string().required("Bitte wählen"),
    }),
    personalization: Yup.string()
      .max(50, "Eingabe zu lang")
      .test("isRequired", "Bitte angeben", function (value) {
        return !personalization || value
      }),
  })

  // default variations
  const variations = product.variations || [
    { name: "mit Umschlag", price: 320 },
    { name: "ohne Umschlag", price: null },
  ]

  return (
    <AddToCardWrapper className="mt-auto ">
      <Formik
        initialValues={{
          variation: null,
          personalization: "",
          quantity: 1,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setCartUpdated(true)

          addItem(
            {
              ...product,
              id:
                id +
                values.variation.name +
                values.personalization.replace(" ", ""),
              price: values.variation.price || price,
              variation: values.variation.name,
              personalization: values.personalization,
            },
            { count: quantity }
          )

          setTimeout(() => setCartUpdated(false), 1500)
        }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <FormGroup className="mb-2">
              <Label
                className={`small mb-1 ${
                  errors.variation && touched.variation
                    ? "text-danger"
                    : "text-muted"
                }`}
              >
                Varianten
              </Label>
              <Input
                style={{
                  borderColor: `${
                    errors.variation && touched.variation ? "red" : "#6c757d"
                  }`,
                }}
                id="variation"
                name="variation"
                type="select"
                default="default"
                onChange={({ target }) => {
                  if (target.value === "-1")
                    setFieldValue("variation", { name: "" })
                  else setFieldValue("variation", variations[target.value])
                }}
                invalid={errors.variation && touched.variation}
              >
                <option value={"-1"}>Variante auswählen</option>
                {variations.map((variation, index) => (
                  <option key={index} value={index}>
                    {variation.name}
                    {" - "}
                    {((variation.price || price) / 100).toLocaleString("de", {
                      minimumFractionDigits: 2,
                    })}{" "}
                    €
                  </option>
                ))}
              </Input>
            </FormGroup>
            {personalization && (
              <FormGroup className="mb-2">
                <Label
                  className={`small mb-1 ${
                    errors.personalization && touched.personalization
                      ? "text-danger"
                      : "text-muted"
                  }`}
                >
                  {personalization}
                </Label>
                <Field
                  style={{
                    borderColor: `${
                      errors.personalization && touched.personalization
                        ? "red"
                        : "#6c757d"
                    }`,
                  }}
                  as={Input}
                  id="personalization"
                  name="personalization"
                  invalid={errors.personalization && touched.personalization}
                />
                <FormFeedback>{errors.personalization}</FormFeedback>
              </FormGroup>
            )}
            <FormGroup className="mb-4">
              <Label className="small mb-1 text-muted">Stückzahl</Label>
              <InputGroup>
                <Input
                  style={{ borderColor: "#6c757d" }}
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    className="px-4"
                    outline
                    disabled={quantity === 1}
                    onClick={() => setQuantity(quantity - 1)}
                  >
                    -
                  </Button>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <Button
                    className="px-4"
                    outline
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    +
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <Button
              className="mb-2"
              type="submit"
              block
              size="lg"
              color={cartUpdated ? "primary" : "dark"}
              disabled={cartUpdated}
              onClick={() => {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "add_to_cart",
                  currency: "EUR",
                  value: (values.variation.price || product.price) / 100,
                  items: [
                    {
                      item_id:
                        product.id +
                        values.variation.name +
                        values.personalization.replace(" ", ""),
                      item_name: product.name,
                      price: (values.variation.price || product.price) / 100,
                      quantity: quantity,
                    },
                  ],
                })
              }}
            >
              {!cartUpdated ? (
                <div className="d-flex align-items-center justify-content-center">
                  <AiOutlineShoppingCart size={18} className="mr-2" />
                  <span>In den Warenkorb legen</span>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <AiOutlineCheck size={18} className="mr-2" />
                  <span>Artikel hinzugefügt</span>
                </div>
              )}
            </Button>
            <div className="text-center small">
              Versand innerhalb von 1-3 Werktagen.
            </div>
          </Form>
        )}
      </Formik>
    </AddToCardWrapper>
  )
}
const EtsyLink = (props) => {
  const { etsyUrl } = props.product

  if (!etsyUrl) return null

  return (
    <AddToCardWrapper className="mt-auto mb-xl-5">
      <a
        className="btn btn-dark btn-lg btn-block"
        // style={{ backgroundColor: "rgb(241, 100, 30)", color: "#fff" }}
        href={etsyUrl}
        target="_blank"
      >
        Auf Etsy kaufen
      </a>
    </AddToCardWrapper>
  )
}

const CategoryLink = ({ category, subcategory }) => {
  if (subcategory)
    return (
      <span className="small">
        {subcategory.map((category, i) => {
          if (category.slug) {
            if (i === 0)
              return (
                <Link
                  key={i}
                  className="text-muted mb-1"
                  to={`/category/${category.slug}`}
                >
                  {category.name}
                </Link>
              )
            else
              return (
                <>
                  {" "}
                  |{" "}
                  <Link
                    key={i}
                    className="text-muted mb-1"
                    to={`/category/${category.slug}`}
                  >
                    {category.name}
                  </Link>
                </>
              )
          } else {
            if (i === 0) return category.name
            else return " | " + category.name
          }
        })}
      </span>
    )

  if (category)
    return <div className="small text-muted mb-1">{category.name}</div>

  return null
}

const ProductInfo = (props) => {
  const { product } = props
  const { description, descriptionLong, details } = product
  const [activeTab, setActiveTab] = useState("1")

  const Bold = ({ children }) => (
    <span className="font-weight-bold">{children}</span>
  )
  const Text = ({ children }) => <p className="text-left mb-3">{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  return (
    <ProductInfoWrapper className="container-fluid">
      <ProductInfoNav
        activeTab={activeTab}
        product={product}
        setActiveTab={setActiveTab}
      />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="pt-4 pb-3">
          <div className="mx-auto text-left" style={{ maxWidth: "45rem" }}>
            {descriptionLong
              ? renderRichText(descriptionLong, options)
              : description
              ? description.description
              : ""}
          </div>
        </TabPane>
        <TabPane tabId="2" className="pt-4 pb-3">
          <div className="d-flex justify-content-center">
            <Table>
              <tbody>
                {details &&
                  details.map((detail) => (
                    <tr key={detail.id}>
                      <th scope="row" className="text-right pr-3">
                        {detail.detailLabel}
                      </th>
                      <td> {detail.detailValue}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </TabPane>
      </TabContent>
    </ProductInfoWrapper>
  )
}

const ProductInfoNav = (props) => {
  const { product, activeTab, setActiveTab } = props
  return (
    <Nav tabs className="justify-content-center">
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === "1" })}
          onClick={() => setActiveTab("1")}
        >
          Beschreibung
        </NavLink>
      </NavItem>
      {product.details && (
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => setActiveTab("2")}
          >
            Produktdetails
          </NavLink>
        </NavItem>
      )}
    </Nav>
  )
}

const ProductInfoWrapper = styled.div`
  min-height: 20rem;
  padding-bottom: 2rem;
  .nav-item {
    margin-bottom: 0;
    .nav-link {
      border: none;
      color: ${({ theme }) => theme.textDefault};
      cursor: pointer;
      :hover {
        color: ${({ theme }) => theme.primary};
      }
    }
    .nav-link.active {
      color: ${({ theme }) => theme.primary};
      border-bottom: ${({ theme }) => `2px solid  ${theme.primary}`};
    }
  }
`

const AddToCardWrapper = styled.div`
  @media (min-width: 768px) {
    .input-group,
    .form-group,
    .btn,
    .small {
      max-width: 20rem;
    }
  }
`

export const query = graphql`
  query ($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      # contentfulid
      id
      name
      slug
      description {
        description
      }
      descriptionLong {
        raw
      }
      details {
        id
        detailLabel
        detailValue
      }
      price
      variations {
        name
        price
      }
      personalization
      category {
        name
        slug
      }
      subcategory {
        name
        slug
        product {
          id
          name
          price
          category {
            name
          }
          subcategory {
            name
            slug
          }
          mainImage {
            gatsbyImageData(
              formats: NO_CHANGE
              placeholder: BLURRED
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
          slug
        }
      }
      mainImage {
        id
        file {
          url
        }
        gatsbyImageData(
          formats: NO_CHANGE
          placeholder: BLURRED
          layout: FULL_WIDTH
          aspectRatio: 1
        )
      }
      images {
        id
        file {
          url
        }
        gatsbyImageData(
          placeholder: BLURRED
          formats: NO_CHANGE
          layout: FULL_WIDTH
          aspectRatio: 1
        )
      }
      linkedProducts {
        id
        name
        price
        category {
          name
        }
        mainImage {
          gatsbyImageData(
            formats: NO_CHANGE
            placeholder: BLURRED
            layout: FULL_WIDTH
            aspectRatio: 1
          )
        }
        slug
      }
      etsyUrl
    }
  }
`
