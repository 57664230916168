import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import styled from "styled-components"

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

import { SRLWrapper } from "simple-react-lightbox"
import SimpleReactLightbox from "simple-react-lightbox"

import { ArrowLeft, ArrowRight } from "../components/sliderButton"

// docs: https://react-slick.neostack.com/docs/example/simple-slider
// background image https://www.gatsbyjs.com/docs/how-to/images-and-media/using-gatsby-plugin-image/#background-images

export default function SlickSlider(props) {
  const { images, settings, options, lightbox = true } = props
  const defautlSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    ...settings,
  }
  const defaultOptions = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
    ...options,
  }

  if (!lightbox)
    return (
      <Slider {...defautlSettings}>
        {images.map((image) => (
          <ImageWapper>
            <GatsbyImage image={getImage(image)} alt="" />
          </ImageWapper>
        ))}
      </Slider>
    )

  return (
    <SimpleReactLightbox>
      <SRLWrapper options={defaultOptions}>
        <Slider {...defautlSettings}>
          {images.map((image) => (
            <ImageWapper key={image.id}>
              <GatsbyImage
                image={getImage(image)}
                style={{ cursor: "pointer" }}
                alt=""
              />
            </ImageWapper>
          ))}
        </Slider>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

const ImageWapper = styled.div`
  img {
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`
